.banner {
  background-color: #5f98ae;
  color: white;
  padding: 10px 10px;
  font-family: 'Arial', sans-serif;
}

.sidebar {
  position: fixed;
  top: 10;
  left: 0;
  width: 200px;
  height: auto;
  padding: 20px;
  box-shadow: -2px 0 2px rgba(0,0,0,0.5);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.sidebar-open {
  transform: translateX(0);
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-title-container {
  display: flex;
  justify-content: center;
}

.greeting-center {
  text-align: center;
  padding: 5px;
}
